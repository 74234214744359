.popup-style {
  width: 440px;
  padding: unset !important;
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: #1b5e20;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #1b5e20;
  }
}

.header-style {
  background-color: #f7f7f8;
  padding: unset !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  align-items: unset !important;
}
.title-style {
  color: #222222;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  width: 400px !important;
  margin-left: 20px !important;
}

.content-style {
  font-size: 14px !important;
  text-align: left !important;
  line-height: 20px !important;
  height: 340px;
  overflow: scroll;
  overflow-x: hidden;
  opacity: 1;
  h3 {
    color: #1b5e20;
    font-weight: bold;
  }
}

.confirm-button {
  width: 440px;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  background-color: #1b5e20 !important;
}
