/* Button */
button.btn-bamba {
    background-color: var(--bamba-light-green);
    color: white;
    outline: none;
}

/* Selects */
div.bamba-select {
  .mat-form-field-appearance-outline {
    .mat-form-field-infix {
      padding: .5em 0;
    }

    &.mat-focused {
      .mat-form-field-outline-thick {
        color: var(--bamba-dark-green);
    }
    }
  }
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

/* Chips */
div.bamba-chip {
  .mat-chip-list-wrapper {
      .mat-standard-chip {
          border: 1px solid var(--bamba-border-gray);
          background-color: var(--bamba-gray);
          border-radius: 4px;
          padding: 7px 10px;

          .mat-icon {
            display: flex;
            align-items: center;
          }
      }
  }
}

/* Form */
.company-form {
  mat-form-field {
    font-size: 14px;
    &.mat-focused {
      .mat-form-field-label {
        color: var(--bamba-dark-green) !important;
      }
      .mat-form-field-ripple {
        background-color: var(--bamba-dark-green);
      }
      .mat-form-field-required-marker {
        color: var(--bamba-dark-green);
      }
    }
    input {
      padding-bottom: 0.3em;
    }
  }
  .mat-form-field-appearance-outline{
    .mat-form-field-flex {
      padding-bottom: 0.3em;
    }
    .mat-form-field-outline-thick {
      color: var(--bamba-dark-green);
      .mat-form-field-outline-start, .mat-form-field-outline-end, .mat-form-field-outline-gap{
        border-width: 1px;
      }
    }
    .mat-form-field-infix {
      padding: .5em 0;
    }
  }
}

/* Tree-list-items */

.tree-item {
    div.mat-list-item-content {
      padding-left: 4.5rem !important;
    }
}

/* container */

.sidenav-container {
  .mat-sidenav {
    .mat-drawer-inner-container {
      overflow: hidden;
    }
  }
}

/* mat-dialog */

.bamba-dialog {
  height: auto;
  .mat-dialog-container {
    position: relative;
    padding-top: 58px;
    .mat-dialog-content {
      max-height: 80vh;
    }
    .add-photos-header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 10px 24px;
      background-color: var(--bamba-gray);
    }
  }
}

.bamba-delete-dialog {
  .mat-dialog-container {
    position: relative;
    padding-top: 58px;
    .modal-header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 10px 24px;
      background-color: var(--bamba-gray);
    }
  }
}

.mat-select-panel{

  max-height: 300px !important;

}

.mat-nav-list a.active{
  color: var(--bamba-violet) !important;
  font-weight: bold;
}

.mat-drawer-container{
  background-color: #ffffff !important;
}

.travelerDataBooking, .bookingDetails {

  .mat-form-field-appearance-outline .mat-form-field-wrapper{
    margin: 0 !important;
  }
  
  .mat-form-field-appearance-outline .mat-form-field-suffix{
    padding: 0 !important;
  }
  
  .mat-form-field-wrapper{
    padding-bottom:  0;
  }
  

}

.travelers-accordion{

  .mat-expansion-panel-body{

    padding: 0 0px 0px !important;

  }

}

