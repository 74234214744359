/* You can add global styles to this file, and also import other style files */

@import "custom-material";
@import "sweet-alert";
@import "material";

/* Webfont: Lato-Black */

@font-face {
    font-family: "LatoWebBlack";
    src: url("/../assets/fonts/Lato-Black.eot");
    /* IE9 Compat Modes */
    src: url("/../assets/fonts/Lato-Black.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("/../assets/fonts/Lato-Black.woff2") format("woff2"),
        /* Modern Browsers */
        url("/../assets/fonts/Lato-Black.woff") format("woff"),
        /* Modern Browsers */
        url("/../assets/fonts/Lato-Black.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

.notification-success {
    background-color: #fff;
    color: #000;
}

.notification-error {
    background-color: #f04a4e;
    color: #fff;
}

.notification-success .mat-simple-snackbar-action {
    color: #1b5e20;
}

.notification-error .mat-simple-snackbar-action {
    color: #fff;
}


/* Webfont: Lato-Bold */

@font-face {
    font-family: "LatoWebBold";
    src: url("/../assets/fonts/Lato-Bold.eot");
    /* IE9 Compat Modes */
    src: url("/../assets/fonts/Lato-Bold.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("/../assets/fonts/Lato-Bold.woff2") format("woff2"),
        /* Modern Browsers */
        url("/../assets/fonts/Lato-Bold.woff") format("woff"),
        /* Modern Browsers */
        url("/../assets/fonts/Lato-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}


/* Webfont: Lato-Light */

@font-face {
    font-family: "LatoWebLight";
    src: url("/../assets/fonts/Lato-Light.eot");
    /* IE9 Compat Modes */
    src: url("/../assets/fonts/Lato-Light.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("/../assets/fonts/Lato-Light.woff2") format("woff2"),
        /* Modern Browsers */
        url("/../assets/fonts/Lato-Light.woff") format("woff"),
        /* Modern Browsers */
        url("/../assets/fonts/Lato-Light.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}


/* Webfont: Lato-Medium */

@font-face {
    font-family: "LatoWebMedium";
    src: url("/../assets/fonts/Lato-Medium.eot");
    /* IE9 Compat Modes */
    src: url("/../assets/fonts/Lato-Medium.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("/../assets/fonts/Lato-Medium.woff2") format("woff2"),
        /* Modern Browsers */
        url("/../assets/fonts/Lato-Medium.woff") format("woff"),
        /* Modern Browsers */
        url("/../assets/fonts/Lato-Medium.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}


/* Webfont: Lato-Regular */

@font-face {
    font-family: "LatoWeb";
    src: url("/../assets/fonts/Lato-Regular.eot");
    /* IE9 Compat Modes */
    src: url("/../assets/fonts/Lato-Regular.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("/../assets/fonts/Lato-Regular.woff2") format("woff2"),
        /* Modern Browsers */
        url("/../assets/fonts/Lato-Regular.woff") format("woff"),
        /* Modern Browsers */
        url("/../assets/fonts/Lato-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}


/* Webfont: Lato-Semibold */

@font-face {
    font-family: "LatoWebSemibold";
    src: url("/../assets//../assets/fonts/Lato-Semibold.eot");
    /* IE9 Compat Modes */
    src: url("/../assets/fonts/Lato-Semibold.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("/../assets/fonts/Lato-Semibold.woff2") format("woff2"),
        /* Modern Browsers */
        url("/../assets/fonts/Lato-Semibold.woff") format("woff"),
        /* Modern Browsers */
        url("/../assets/fonts/Lato-Semibold.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}


/* Webfont: Lato-Thin */

@font-face {
    font-family: "LatoWebThin";
    src: url("/../assets/fonts/Lato-Thin.eot");
    /* IE9 Compat Modes */
    src: url("/../assets/fonts/Lato-Thin.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("/../assets/fonts/Lato-Thin.woff2") format("woff2"),
        /* Modern Browsers */
        url("/../assets/fonts/Lato-Thin.woff") format("woff"),
        /* Modern Browsers */
        url("/../assets/fonts/Lato-Thin.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}


/* Webfont: Lato-Thin */

@font-face {
    font-family: "KhandBold";
    src: url("/../assets/fonts/khand/Khand-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

html,
body {
    height: 100%;
    font-family: Lato;
}

*,
body {
    margin: 0;
    font-family: Lato, sans-serif;

    .mat-body,
    .mat-body-1,
    .mat-typography {
        font-family: Lato;
    }

    summary::marker {
        display: none;
    }

    summary::-webkit-details-marker {
        display: none;
    }
}

* {
    // --bamba-dark-green: #1b5e20;
    // --bamba-light-green: #2fc1d1;
    // --bamba-green-disabled: #9bc2c7;
    // --bamba-violet: #1b5e20;
    // --bamba-violet-disabled: #988ebd;
    // --bamba-gray: #f7f7f8;
    // --bamba-dark-gray: #939393;
    // --bamba-border-gray: #a8b7c4;
    // --cropper-outline-color: rgba(0, 0, 0, .4);
    // --cropper-overlay-color: rgba(0,0,0,1);
    --bamba-dark-green: #1b5e20;
    --bamba-light-green: #00e676;
    --bamba-green-disabled: rgba(27, 94, 32, 0.38);
    --bamba-violet: #1b5e20;
    --bamba-violet-disabled: rgba(27, 94, 32, 0.38);
    --bamba-gray: #f9fbfa;
    --bamba-dark-gray: #8b928c;
    --bamba-border-gray: #dce5e1;
    --cropper-outline-color: #eaf0ea;
    --cropper-overlay-color: rgba(0, 0, 0, 0.6);

    // mat-input-autocomplete
    // contiene los estilos de bamba
    .example-chip-list>.mat-form-field {
        width: 100%;

        &.mat-form-field-appearance-outline {
            .mat-form-field-wrapper {
                .mat-form-field-outline-thick {
                    color: var(--bamba-dark-green);

                    .mat-form-field-outline-start,
                    .mat-form-field-outline-gap,
                    .mat-form-field-outline-end {
                        border-width: 1px;
                    }
                }

                .mat-form-field-infix {
                    padding: 0 0 0.6em 0;
                }
            }
        }

        mat-chip {
            &.mat-standard-chip {
                background-color: var(--bamba-gray);
            }
        }
    }
}

.title-into-section {
    color: var(--bamba-dark-green);
    font-size: 16px !important;
    line-height: 1 !important;
    margin-bottom: 2px !important;
    font-weight: 500;
}

.subtitle-into-section {
    color: #939393;
    font-size: 13px;
}

.bg-bamba-violet {
    background-color: var(--bamba-violet);
    color: white;

    &:disabled {
        background-color: #a8b7c4;
        cursor: not-allowed;
    }
}

.bg-bamba-transparent {
    background-color: transparent;
}

.bg-tertiary-gradient {
    color: white;
    background: #286B2A;
    border-radius: 40px !important;
    height: 40px;
    padding: 0px 16px 0px 24px !important;
}

input:disabled {
    background-color: #f7f7f8 !important;
}

.bg-bamba-gray {
    background-color: var(--bamba-gray);
}

.bg-bamba-green {
    background-color: var(--bamba-light-green);
    color: white;
}

.bg-bamba-light-green {
    background-color: var(--bamba-light-green);
    color: black;
}

button.btn,
input.btn,
.btn {
    padding: 6px 52px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    line-height: 2;
    font-weight: 500;
    font-size: 16px;
}

a.btn {
    text-align: center;
    text-decoration: none;
}

.btn-green {
    color: white;
    background-color: var(--bamba-light-green);
    transition: all 0.5s;
    outline: none;

    &:hover {
        background-color: var(--bamba-dark-green);
    }

    &:disabled {
        background-color: var(--bamba-green-disabled);
    }
}

div.expertise-check-group,
div.featured-checkbox,
div.leader-checkboxes {
    .bamba-input {
        outline-color: var(--bamba-dark-green);
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        padding-left: 10px;

        &:hover {
            border-color: var(--bamba-dark-green);
        }
    }

    .mat-checkbox {
        background-color: var(--bamba-gray);
        padding: 8px 10px;
        margin-bottom: 10px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;

        label.mat-checkbox-layout {
            .mat-checkbox-inner-container {
                background-color: white;
                width: 22px;
                height: 22px;
                border-radius: 50%;

                .mat-checkbox-frame {
                    border-width: 1px;
                    border-style: solid;
                    border-radius: 50%;
                }

                .mat-checkbox-background {
                    border-radius: 50%;
                }

                .mat-checkbox-checkmark {
                    top: 2px;
                    left: 3px;
                    width: 75%;
                }
            }
        }

        &.mat-checkbox-checked.mat-accent {
            .mat-checkbox-inner-container {
                .mat-checkbox-frame {
                    border-style: none;
                }

                .mat-checkbox-background {
                    background-color: var(--bamba-light-green);
                }
            }
        }
    }
}

.w-100 {
    width: 100%;
}

.w-75 {
    width: 75%;
}

.w-50 {
    width: 50%;
}
.w-49 {
  width: 49%;
}
.w-25 {
    width: 25%;
}

.mb {
    margin-bottom: 20px;
}

.fxFlex {
    display: flex;
}

.jc-space-between {
    justify-content: space-between;
}

.color-purple {
    color: var(--bamba-violet);
}

.group-toggle {
    display: flex;
    align-items: center;

    &__title {
        margin-right: 1rem;
    }
}

.bamba-input {
    padding: 8px 10px;
    border-radius: 4px;
    border: 1px solid var(--bamba-dark-gray);
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    &:hover {
        border-color: var(--bamba-dark-green);
    }

    &:focus {
        border-color: var(--bamba-dark-green);
        outline-color: var(--bamba-dark-green);
    }
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--bamba-dark-green);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: var(--bamba-dark-green);
}

div.trip-languages-select {
    .mat-form-field-appearance-outline .mat-form-field-infix {
        padding: 6px 0;
    }

    .mat-form-field-appearance-outline .mat-form-field-wrapper {
        margin: 0;
    }
}

.mat-tab-label {
    text-align: center;
    font: normal normal normal 14px/14px Lato;
    letter-spacing: 0px;
    color: var(--bamba-dark-gray);
    opacity: 1;
}

//26-02 jaydeep
.mat-tab-list .mat-tab-labels .mat-tab-label-active {
    text-align: center;
    // font: normal normal bold 14px/14px Lato !important;
    font: normal normal 500 14px/14px Rubik !important;
    letter-spacing: 0px;
    color: #1b5e20 !important;
    opacity: 1;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background: #1b5e20;
    height: 2px;
}

// .mat-progress-spinner circle,
// .mat-spinner circle {
//   stroke: #1b5e20 !important;
// }
.mat-form-field-appearance-outline .mat-form-field-outline {
    color: var(--bamba-dark-gray) !important;
    background-color: white;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: var(--bamba-dark-green) !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
    border-width: 1px !important;
}

.bamba-group_buttons {
    box-shadow: 0px 3px 6px #00000029 !important;
}

.mat-progress-bar {
    height: 10px !important;
}

.mat-progress-bar-fill::after {
    background-color: #1b5e20 !important;
}

.scrollTop-link {
    text-decoration: underline;
    cursor: pointer;
    color: #767a7e !important;
}

.changematcolor {
    background: #e05f2f !important;
    color: white !important;
}


/* 23-02 */

// .second-con .mat-form-field-wrapper {
//   padding-bottom: 0px !important;
// }
.mat-radio-label-content {
    color: #1b5e20 !important;
}

.boldline {
    font-weight: bolder !important;
}

.mat-dark-green .mat-badge-content {
    background: #1b5e20;
    display: inline-block;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: -8px;
}

.mat-badge-medium .mat-badge-content {
    width: 15px;
    height: 15px;
    line-height: 15px;
}

.MessageField .is-typing .send-button {
    color: #225e20 !important;
    opacity: 1;
}

.close {
    cursor: pointer;
}

.dialog-container-custom-template .mat-dialog-container {

    border-radius: 50px;
}

.dialog-container-custom-template .modal-content {
    max-height: 75vh;
}

.dialog-container-custom-template2 .mat-dialog-container {

    border-radius: 50px;
}

.dialog-container-custom-template2 .modal-content {
    max-height: 75vh;
}

.dialog-container-custom .mat-dialog-container {
    padding: 0 !important;
    border-top-left-radius: 3%;
    border-top-right-radius: 3%;
}

.dialog-container-custom .modal-content {
    margin: 10px !important;
}

.dialog-container-custom .mat-dialog-content {
    max-height: 70vh;
}

@media (max-width:767px) {
    .dialog-container-custom .mat-dialog-container {
        border-top-left-radius: 0 !important;
        ;
        border-top-right-radius: 0 !important;
        ;
    }
}

.btn-circle {
    border-radius: 25px !important;
    padding: 6px 39px !important;
}

.mat-dialog-content .mat-form-field-wrapper {
    padding: 0px !important;
}


//
.locs-tags-info .mat-standard-chip {
    border: 1px solid #B2B3B3;
    border-radius: 8px;
    background-color: #fff !important;
    width: auto;
    height: 32px;
    letter-spacing: 0.1px;
    color: #424840;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px !important;
}

.locs-tags-info-parent .locs-tags-info .mat-standard-chip {
    margin: 6px 0 1px 5px !important;
}

.locs-tags-info .mat-icon {
    color: #286B2A !important;
    margin-right: 5px;
}

.locs-tags-info .mat-standard-chip .mat-chip-remove.mat-icon {
    color: #424840 !important;
    opacity: 1 !important;
}

.file-uplaod-imgs-fix .delete-button {
    bottom: 6px;
    top: auto !important;
    right: -12px !important;
}

.file-uplaod-imgs-fix .fix-imgs {
    object-fit: cover;
    height: 88px;
    border: 1px dashed #B2B3B3;
}

.dialog-container-custom-template2 .mat-dialog-container {
    max-width: 900px;
    margin: auto;
    background: #F9FBFA;
    box-shadow: 0px 2px 6px rgb(60 64 67 / 16%);
    border-radius: 16px;
    height: 875px;
}

.dialog-container-custom-template .mat-dialog-container {
    max-width: 900px;
    margin: auto;
    background: #F9FBFA;
    box-shadow: 0px 2px 6px rgb(60 64 67 / 16%);
    border-radius: 16px;
    height: 880px;
}

.dialog-container-choose-create-proposal .mat-dialog-container {
    max-width: 900px;
    margin: auto;
    background: #F9FBFA;
    box-shadow: 0px 2px 6px rgb(60 64 67 / 16%);
    border-radius: 16px;
    height: auto;
}

.buttons-size-comn button {
    height: 50px;
    margin: 0 0 0 10px !important;
}

.dialog-container-custom-template p.subtitle-into-section {
    color: #424840 !important;
    ;
    font-weight: 500 !important;
    ;
    font-size: 14px !important;
    ;
    line-height: 18px !important;
    ;
}

.description-temp span {
    display: none !important;
}

::ng-deep {
    .ChatHeader__header {
        background-color: #F9FBFA !important
    }
    .MessageField.regular .entry-form{
        background: #E9F5EA !important;
    }

    input.country-search{
      padding: 10px 15px 16px!important;
    }


}

.mat-tel .mat-form-field-infix{
  width: 100%!important;
}

.mat-telinput{
  width: 100%!important;
}

.country-selector{
  opacity: 1!important;
}

.mat-menu-content:not(:empty){
  max-height: 250px!important;
}


.radioButtons .mat-radio-label-content{
  font-weight: 500;
    font-size: 14px;
}
.frm-lbl-info .mat-form-field-label{
  padding: 2px;
  background: #fff;
  color: #8b928cd1;
  font-size: 14px;
  width: auto !important;
}

input.country-search {
  width: 100%;
  height: 25px!important;
  padding: 10px 5px 10px!important;
  border: none;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}

.custom-backdrop{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: none;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  background: rgba(0, 0, 0, 0.32)
}

@import "~@mobiscroll/angular/dist/css/mobiscroll.angular.scss";
.mbsc-material.mbsc-selected .mbsc-calendar-cell-text {
  border: none !important;
}
.mbsc-calendar-title-wrapper .mbsc-calendar-button {
  pointer-events: none !important;
}

/* Body/Body 2 */
.text-body-2{
    // font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

/* Headline/Headline 5 */
.text-headline-5 {
    font-size: 24px;
    // font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    line-height: 32px;
}

/* Subheader/S2 */
.text-subheader-s2 {
    // font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

/* Subheader/S1 */
.text-subheader-s1 {
    // font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

/* Button/Button */
.text-button {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
}




