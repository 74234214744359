.mat-form-field-infix {
  border-top: 0.4em solid transparent;
}
.company-form mat-form-field input {
  padding: 0;
}
.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
  padding: 0.5em 0;
}

.mat-form-field-appearance-outline .mat-form-field-label {
  margin-top: -0.4em;
}

.mat-select-arrow-wrapper {
  padding-top: 0.8em;
}

// Mat Form Field Styles
.mat-form-field-appearance-outline .mat-form-field-infix {
  border-top: 0.4em solid transparent;
  padding: 0.4em 0;
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
}

.mat-form-field-infix input {
  padding: 0;
}

.mat-form-field-label mat-label {
  line-height: normal;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--bamba-violet) !important;
}

.mat-progress-bar .mat-progress-bar-buffer {
  background: var(--bamba-gray) !important;
}

.search-field {
  .mat-form-field-wrapper {
    height: 25px;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: unset !important;
    background: unset;
  }

  .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    padding-top: 1.6em;
  }

  .mat-form-field:not(.mat-form-field-appearance-legacy)
    .mat-form-field-suffix
    .mat-icon-button {
    width: 2em !important;
    height: 2em !important;
    padding-bottom: 0.3em;
  }

  .mat-form-field:not(.mat-form-field-appearance-legacy)
    .mat-form-field-suffix
    .mat-icon-button {
    margin-right: 7px;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: none;
  }

  .mat-form-field-infix {
    border-top: 0em solid transparent;
  }

  .mat-select-arrow-wrapper {
    padding-top: unset;
  }

  .mat-date-range-input-container {
    font-size: smaller;
  }
}
table {
  th.mat-header-cell:last-of-type,
  td.mat-cell:last-of-type,
  td.mat-footer-cell:last-of-type {
    padding-right: unset;
  }
}

.assign-to {
  .mat-form-field {
    height: 20px;
    width: 120px;
  }
  .mat-form-field-wrapper {
    height: 20px;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 0.25em 0.75em 0em 0.2em;
    background: none;
  }
  // .mat-select-arrow-wrapper {
  //   padding-top: 0em;
  // }

  .mat-form-field-appearance-fill .mat-form-field-infix {
    padding: 0em;
  }

  .mat-form-field-infix {
    border-top: 0em solid transparent;
  }
}

.inquiry-buttons {
  .mat-raised-button {
    min-width: auto;
  }
}

.larger-text-form-field {
  font-size: small;
}

.mat-tab-labels{
  background-color: #F7F7F8;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background{
  background-color: var(--bamba-dark-green) !important;
}
